import React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import Seo from "../components/Seo"

const PrivacyPage = ({ data }) => {
  return (
    <Layout>
      <Seo seo="" />
      <Container>
        <div className="max-w-[45rem] mx-auto pb-16 md:pb-32">
          <header className="max-w-4xl pt-8 md:pt-16 pb-4">
            <h1 className="text-[2.625rem] md:text[3.375rem] lg:text-6xl font-light">
              Privacy Policy
            </h1>
          </header>
          <main className="prose prose-invert lg:prose-lg max-w-none prose-li:marker:text-current font-light prose-h2:font-light">
            <p>
              DeepTech Ventures AG, Seedammstrasse 3, 8808 Pfäffikon SZ,
              Switzerland, is the operator of this website, accessible from
              www.deeptechventures.io. DeepTech Ventures AG is responsible for
              the collection, processing, and use of your personal data and must
              ensure compliance with Swiss law and possibly applicable European
              law. We take the protection of your personal data very seriously.
              We treat your personal data confidentially and according to the
              legal data protection regulations as well as this privacy policy.
              Here we inform you which data is collected on this website and how
              it is used. You will also learn how you can review the accuracy of
              this information and arrange for us to remove this data. In
              cooperation with our hosting providers, we endeavour to protect
              the databases as much as possible from unauthorized access,
              losses, misuse or falsification. We would like to remind you that
              data transmission over the Internet (e.g.communication by e-mail)
              can be subject to security vulnerabilities. A complete protection
              of the data against access by third parties is not possible. By
              using this website, you consent to the collection, processing and
              use of data in accordance with this policy. This website can be
              visited without registration. Data such as pages accessed or names
              of files accessed, date and time are stored on the server for
              statistical purposes without this data being directly related to
              your person. Personal data, in particular name, address or e-mail
              address are collected to the extent possible on a voluntary basis.
              The data will not be made available to third parties without your
              consent.
            </p>
            <h2>1. SCOPE OF APPLICATION</h2>
            <p>
              Personal data is all information that refers to an identified or
              identifiable person. A data subject is a person about whom
              personal data is processed. Processing includes any handling of
              personal data, regardless of the means and procedures used, in
              particular the storage, disclosure, retrieval, deletion,
              recording, alteration, destruction and use of personal data. We
              process personal data in accordance with Swiss data protection
              law. Insofar and to the extent that the European General Data
              Protection Regulation (EU-GDPR) is applicable, we further process
              personal data in accordance with the following legal provisions in
              connection with Art. 6 para.
            </p>
            <p>1 EU-GDPR:</p>
            <ul>
              <li>
                Point a) processing of personal data with the consent of the
                data subject.
              </li>
              <li>
                Point b) Processing of personal data for the performance of a
                contract with the data subject and for the implementation of
                corresponding pre-contractual measures.
              </li>
              <li>
                Point c) Processing of personal data to discharge a legal
                obligation to which we are subject under any applicable EU law
                or under any applicable law of a country in which the EU-GDPR is
                applicable in whole or in part.
              </li>
              <li>
                Point d) The processing of personal data for the purpose of
                protecting the vital interests of the data subject or another
                natural person.
              </li>
              <li>
                Point f) Processing of personal data to protect the legitimate
                interests of us or third parties, unless the fundamental
                freedoms and rights and interests of the data subject prevail.
                In particular, legitimate interests include our business
                interest in being able to provide our website, information
                security, the enforcement of our own legal claims and compliance
                with Swiss law. We process personal data for the duration
                necessary for the respective purpose or purposes. In the case of
                longer-term storage obligations due to legal and other
                obligations to which we are subject, we limit the processing
                accordingly.
              </li>
            </ul>
            <h2>2. COLLECTION, USE AND PROCESSING OF PERSONAL DATA</h2>
            <p>2.1 When you visit our Website</p>
            <p>
              When you visit our website, certain data is automatically stored
              on our servers or on servers of services and products that we
              procure and/or have installed for purposes of system
              administration, statistics, security, or tracking. This
              information may include the following: the name of your Internet
              service provider your IP address (under certain circumstances) the
              version of your browser software the operating system of the
              computer with which our website was accessed the date the time the
              website from which you are visiting our website the search words
              you used to find our website. Under certain circumstances, this
              data may allow conclusions to be drawn about a particular visitor.
              However, personal data is not used in this context.
            </p>
            <p>2.2 Third Parties</p>
            <p>
              We will only pass on your data if we are obliged to do so by law
              or by official or court orders. Your personal data will not be
              passed on to third parties for purposes other than those stated
              herein.
            </p>
            <p>2.3 Cookies</p>
            <p>
              We generally use cookies on our website. These are small text
              files which are stored by your web browser and which store certain
              settings and data for exchange with our web server. This
              technology allows us to recognize individual users as (returning)
              visitors. In general, the use of cookies is intended to improve
              our services, make them more effective and safer. A cookie does
              not always mean that we can identify you. You can adjust your
              browser settings so that no cookies are stored on your computer.
              The complete deactivation of cookies may result in you not being
              able to use all functions of our website. By continuing to use our
              website and/or by agreeing to this privacy policy, you consent to
              the use of cookies by us to collect, store and use your personal
              usage data beyond the end of your browser session. You may revoke
              this consent at any time by activating your browser's setting to
              block third-party cookies.
            </p>
            <p>2.4 Newsletter</p>
            <p>
              If you subscribe to our newsletter, you agree that we may use the
              data provided by you, such as your first and last name, address,
              e-mail address and any data you have voluntarily entered in your
              user profile, to send you the newsletter.
            </p>
            <p>2.5 Analytics Tools</p>

            <p>
              Our website may use Google Analytics, a web analysis service
              provided by Google Inc. 1600 Amphitheatre Parkway, Mountain View,
              CA 94043, USA. Google Analytics uses "cookies", which are text
              files placed on your web browser, to help the website analyze how
              users use the site (see above Cookies). The information generated
              by the cookie (browser type/version, operating system used,
              referrer URL / previously visited website, IP address, time of
              server request) about your use of this website is generally
              transmitted to a Google server in the U.S. and stored there.
              However, if IP anonymization is activated on our website, your IP
              address will be shortened beforehand by Google within member
              states of the European Union or in other signatory states to the
              Agreement on the European Economic Area. Only in exceptional cases
              is the full IP address transmitted to a Google server in the USA
              and shortened there. Google will use this information for the
              purpose of evaluating your use of the website, compiling reports
              on website activity for website operators and providing other
              services relating to website activity and internet usage. Google
              may also transfer this information to third parties where required
              to do so by law, or where such third parties process the
              information on Google's behalf. The IP address transmitted by your
              browser within the scope of Google Analytics is not combined with
              other Google data. You may refuse the use of cookies by selecting
              the appropriate settings on your browser, however please note that
              if you do this you may not be able to use the full functionality
              of this website. By using this website, you consent to the
              processing of data about you by Google in the manner and for the
              purposes described above. Further information about the web
              analysis service used can be found on the Google Analytics
              website. Instructions on how to prevent your data from being
              processed by the web analysis service can be found at
              http://tools.google.com/dlpage/gaoptout?hl=en.
            </p>

            <h2>3. YOUR RIGHTS</h2>

            <p>
              You can request information about your data stored by us at any
              time. We kindly ask you to send a request for information by
              e-mail to the address below. Together with the request, you will
              need proof of your identity at the same address. You have the
              possibility to request the deletion or correction of your data at
              any time. You are of course also entitled at any time to revoke
              your consent to the use or processing of personal data with effect
              for the future. Stored data will be deleted by us if they are no
              longer needed for the purpose indicated. With regard to the
              deletion of data, it should be noted that we are subject to
              certain legal obligations, which provide for certain data to be
              retained. We have to comply with this obligation. If you wish the
              deletion of data which are subject to the legal storage
              obligation, the data will be blocked in our system and only used
              to fulfil the legal storage obligations. After expiry of the
              retention period, your request for deletion will be complied with.
              If your personal data can be processed on the basis of legitimate
              interests, you have the right to object to the processing of your
              personal data if there are reasons for doing so which arise from
              your particular situation or the objection is directed against
              direct marketing. In the latter case, you have a general right of
              objection, which is implemented by us without the need to indicate
              a particular circumstance. Furthermore, you also have the option
              to receive your personal data, which you have provided to us, or
              to request the transfer to another responsible person. We kindly
              ask you to send us an e-mail at info@deeptechventures.io. We
              reserve the right to require you to provide us with an identity
              card and to assume the effective costs in advance should the
              effort involved be disproportionately high.
            </p>

            <h2>4. CONTACT DETAILS</h2>

            <p>
              Contact details:
              <br />
              DeepTech Ventures AG
              <br /> Seedammstrasse 3<br />
              8808 Pfäffikon SZ, Switzerland
              <br />
              info@deeptechventures.io
              <br />
              www.deeptechventures.io
            </p>

            <h2>5. REVISIONS OF THIS PRIVACY POLICY</h2>

            <p>
              We may change or adapt this privacy policy at any time. The
              current privacy policy can be accessed at;
              www.deeptechventures.io/privacy-policy.
            </p>

            <p>Pfäffikon SZ, 10 June 2022</p>
          </main>
        </div>
      </Container>
    </Layout>
  )
}

export default PrivacyPage
